.StatusMessage-module_status-message_saxxR__Main {
  border-radius: 10px;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.StatusMessage-module_status-message__icon_k0e14__Main {
  width: 25px;
  height: 25px;
}

.StatusMessage-module_status-message--warning_-tkjV__Main {
  border: 1px solid var(--icons-yellow);
  background: var(--icons-yellow-opacity-20);
}

.StatusMessage-module_status-message__info_XAEMX__Main {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.StatusMessage-module_status-message__title_yXZep__Main, .StatusMessage-module_status-message__text_xk6Z1__Main {
  color: var(--text-primary);
}

